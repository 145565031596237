import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  DynamicTiles,
  MultipleComponentIterator,
  Accordion,
  SlideReveal,
  FishermanIcon,
  MarkdownContent,
  InternalLink,
  MultipleComponentIteratorMap,
  PriceRange,
  FadeReveal,
  BasicCarousel,
  componentIterator,
  Image,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  ScrollableContent,
  FullImageCard,
  Map,
  Location,
  Email,
  Phone,
  HoursOfOperation,
  TeamMemberV2,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  withArgs,
  getComponentContentNodeContent,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      dynamicTilesComponent,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessLocation,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Section className={"custom-hero-section"}>
            <DynamicTiles className={"custom-hero"}>
              <MultipleComponentIterator
                iterator={dynamicTilesComponent.childConfigurations}
                components={[
                  {
                    component: (
                      <DynamicTiles.Tile
                        imageProps={{ loadStrategy: "eager" }}
                      />
                    ),
                    propMap: {
                      images: "files",
                      content: "contentNodes",
                      interactions: "interactions",
                      className: "data.className",
                      overlayClassName: "data.overlayClassName",
                      width: "data.width",
                    },
                  },
                ]}
              />
            </DynamicTiles>
          </Section>
          <div className={"custom-content-container"}>
            <div className={"hero-overlay"} />
            <div className={"hero-img"} />
            <div className={"hero-content"}>
              <h2>What Sets Stella Apart</h2>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"center"}
                centered={true}
                column={"4"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column mobile={16} computer={4} textAlign={"center"}>
                    <h4>Gratuity Free</h4>
                    <p>
                      <span>
                        No tip anxiety here! We value your support, but we
                        proudly operate as a gratuity free salon. You’ll always
                        know the exact cost of your appointment at the time of
                        booking. The best way to show your appreciation?
                      </span>
                      <a href={"https://g.co/kgs/qtVQgxf"} target={"_blank"}>
                        Leave a review
                      </a>
                      <span>&nbsp;and spread the word!</span>
                    </p>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={4} textAlign={"center"}>
                    <h4>Gender Free Service Menu</h4>
                    <p>
                      We offer an inclusive, gender-free service menu designed
                      to welcome everyone. Our goal is to create a safe and
                      comfortable space. Just select your preferred stylist and
                      service duration, and we’ll ensure you have a comfortable
                      and enjoyable experience!
                    </p>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={4} textAlign={"center"}>
                    <h4>Hourly Pricing</h4>
                    <p>
                      Hourly pricing allows you to pay a fixed rate for the time
                      you spend with your stylist, rather than individual prices
                      for each service. This approach ensures transparency, with
                      no hidden fees or unexpected charges. You can relax and
                      enjoy your visit, assured that you know the total cost
                      beforehand.
                    </p>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={4} textAlign={"center"}>
                    <h4>No Double Booking</h4>
                    <p>
                      Included in our hourly price is our full attention. We
                      strive to give you the individual care you deserve. By
                      focusing on one client at a time, we ensure that you
                      receive our complete focus and enjoy a relaxed and stress
                      free environment.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <div id={"services-section"} className={"services-section"}>
            <Accordion exclusive={true} className={"services-accordion"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={6}
                components={[
                  {
                    component: <React.Fragment />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <Accordion.Title />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: (
                              <SlideReveal
                                fade={true}
                                direction={"right"}
                                firstAnimation={{ direction: "up" }}
                              />
                            ),
                            children: [
                              {
                                component: <Header as={"h2"} />,
                                children: [
                                  {
                                    component: (
                                      <span children={{ name: "name" }} />
                                    ),
                                    propMap: { children: "name" },
                                  },
                                  {
                                    component: (
                                      <FishermanIcon iconName={"plus"} />
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: <Accordion.Content />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: <MarkdownContent />,
                            propMap: { content: "description" },
                          },
                          {
                            component: (
                              <Button
                                primary={true}
                                content={"View Details"}
                                event={{
                                  category: "Services",
                                  action: "View Category Details",
                                }}
                                as={InternalLink}
                              />
                            ),
                            propMap: {
                              "event.label": "name",
                              to: createServiceCategorySlug,
                            },
                          },
                          {
                            component: (
                              <Grid
                                className={"service-category-items"}
                                doubling={true}
                                stackable={true}
                                columns={2}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <MultipleComponentIteratorMap
                                    lookupField={"_id"}
                                    iterator={
                                      allFishermanBusinessMenuItem.nodes
                                    }
                                    components={[
                                      {
                                        component: <Grid.Column />,
                                        children: [
                                          {
                                            component: (
                                              <InternalLink
                                                event={{
                                                  category: "Services",
                                                  action: "View Item Details",
                                                }}
                                              />
                                            ),
                                            propMap: {
                                              "event.label": "name",
                                              to: withArgs({
                                                func: createServiceItemSlug,
                                                args: [
                                                  allFishermanBusinessMenuCategory.nodes,
                                                ],
                                              }),
                                            },
                                            children: [
                                              {
                                                component: <Header as={"h3"} />,
                                                propMap: { children: "name" },
                                              },
                                              {
                                                component: (
                                                  <PriceRange
                                                    priceKey={"price"}
                                                    settings={{
                                                      displayType: "range",
                                                      displayZeroCost: false,
                                                    }}
                                                  />
                                                ),
                                                propMap: {
                                                  prices: "variations",
                                                },
                                              },
                                              {
                                                component: (
                                                  <FishermanIcon
                                                    iconName={"arrow-right"}
                                                  />
                                                ),
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ]}
                                  />
                                ),
                                propMap: { targetValues: "items" },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Accordion>
          </div>
          <Section className={"about-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <div>
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    className={"about-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "reviews_section_background_image",
                numToSelect: 1,
              })}
            >
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "reviews_section_header",
                  defaultValue: "What Our Clients Say",
                })}
              />
              <BasicCarousel
                size={1}
                displayIndicators={true}
                stepSize={1}
                autoScroll={false}
                leftArrowIcon={"arrow-left"}
                rightArrowIcon={"arrow-right"}
                displayPlayPauseButton={false}
                slide={true}
                items={multipleComponentIterator({
                  iterator: fishermanBusiness.reviews,
                  components: [
                    {
                      component: <ReviewV2 />,
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: <ReviewV2.Text charLimit={440} />,
                          propMap: { text: "text" },
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Rating />,
                              propMap: { rating: "rating" },
                            },
                            {
                              component: <ReviewV2.RatingLabel />,
                              propMap: { rating: "rating" },
                            },
                          ],
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Author />,
                              propMap: { author: "author" },
                            },
                            {
                              component: <ReviewV2.Source />,
                              propMap: { source: "source", link: "link" },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                })}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
                triggerButtonProps={{ secondary: true }}
                buttonText={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "submit_review_button",
                  defaultValue: "Submit Review",
                })}
              />
            </Image>
          </Section>
          <Section className={"team-section"}>
            <ScrollableContent
              direction={"horizontal"}
              distanceFactor={"snap"}
              intervalFactor={500}
            >
              <Grid stackable={true} columns={2}>
                <Grid.Column className={"content-column"} width={4}>
                  <Header
                    as={"h2"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_header",
                      defaultValue: "Our Team",
                    })}
                  />
                  <Header
                    as={"h3"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_subheader",
                      defaultValue: "Boost Your True Beauty",
                    })}
                  />
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_description",
                    })}
                  />
                  <Button
                    as={InternalLink}
                    to={"/team/"}
                    primary={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "see_all_team_button",
                      defaultValue: "See Our Team",
                    })}
                    className={"see-more-button"}
                    event={{
                      category: "Team",
                      action: "View All Team Members",
                    }}
                  />
                  <div className={"triggers-container"}>
                    <ScrollableContent.PreviousTrigger />
                    <ScrollableContent.NextTrigger />
                  </div>
                </Grid.Column>
                <Grid.Column className={"team-member-cards-column"} width={12}>
                  <ScrollableContent.Content hideScrollbar={true}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessTeam.nodes}
                      components={[
                        {
                          component: (
                            <FadeReveal
                              className={"ui card"}
                              triggerOnce={true}
                            />
                          ),
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <FullImageCard
                                  link={true}
                                  className={"team-member-card"}
                                  as={InternalLink}
                                  event={{
                                    category: "Team",
                                    action: "View Team Member",
                                  }}
                                />
                              ),
                              propMap: {
                                key: "_id",
                                image: "gatsbyImage",
                                to: createTeamMemberSlug,
                              },
                              children: [
                                {
                                  component: <TeamMemberV2.Name />,
                                  propMap: { name: "name" },
                                },
                                {
                                  component: <TeamMemberV2.Role />,
                                  propMap: { role: "role" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </ScrollableContent.Content>
                  <div className={"actions-container"}>
                    <Button
                      as={InternalLink}
                      to={"/team/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_all_team_button",
                        defaultValue: "See Our Team",
                      })}
                      className={"see-more-button"}
                      primary={true}
                    />
                    <div className={"triggers-container"}>
                      <ScrollableContent.PreviousTrigger />
                      <ScrollableContent.NextTrigger />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </ScrollableContent>
          </Section>
          <div
            className={"elfsight-app-1c5b72c4-401c-494b-bce2-af0f26970b63"}
            data-elfsight-app-lazy={true}
          />
          <Section className={"locations-section"}>
            <Grid columns={2} stackable={true}>
              <Grid.Column width={8} className={"carousel-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <Map
                    businessName={fishermanBusiness.name}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"information-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div className={"information-content"}>
                    <Header
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_header",
                      })}
                    />
                    <Header
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "locations_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      as={InternalLink}
                      to={"/contact/"}
                      content={"Contact Us"}
                    />
                  </div>
                  <Card.Group className={"locations-cards"}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessLocation.nodes}
                      components={[
                        {
                          component: <Card />,
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: <Card.Content />,
                              children: [
                                {
                                  component: <Card.Header />,
                                  propMap: { content: "name" },
                                },
                                {
                                  component: <Card.Description />,
                                  children: [
                                    {
                                      component: <Location inline={true} />,
                                      propMap: {
                                        "address.street": "street",
                                        "address.city": "city",
                                        "address.state": "state",
                                        "address.zip": "zipCode",
                                      },
                                    },
                                    {
                                      component: <Email />,
                                      propMap: { email: "email" },
                                    },
                                    {
                                      component: <Phone />,
                                      propMap: { phone: "phoneNumber" },
                                    },
                                  ],
                                },
                                {
                                  component: (
                                    <HoursOfOperation
                                      displayOption={"dailyWithPopup"}
                                    />
                                  ),
                                  propMap: { hours: "hours" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </Card.Group>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
      primaryLocation {
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    dynamicTilesComponent: fishermanWebsiteComponent(
      page: { pageType: { eq: "Home" } }
      fastId: { eq: "DynamicTiles" }
    ) {
      childConfigurations {
        data {
          className
          overlayClassName
          width
        }
        files {
          _id
          altText
          file
          order
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          _id
          content
          contentType
          contentName
          order
        }
        interactions {
          _id
          displayType
          behaviorType
          enabled
          label
          order
          style
          url
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [order], order: [ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
        state
        zipCode
        email
        hours {
          open
          close
          day
          label
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
